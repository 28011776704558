import {
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import {
	home,
	storefrontOutline,
	desktopOutline,
	listCircle,
	logOut,
	trophy,
	informationCircle,
} from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { authStateChanged } from './features/auth/authSlice';
import './CustomerMenu.scss';
import { logout } from './services/firestore';

const CustomerMenu: React.FC = () => {
	const dispatch = useDispatch();
	return (
		<IonMenu
			side='start'
			contentId='customerMenu'
			menuId='customerMenu'
			id='customerMenu'
			className='side-menu'
			type='overlay'
		>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Menu</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					<IonMenuToggle>
						<IonItem routerLink='/customer-home' routerDirection='forward'>
							<IonLabel>Home</IonLabel>
							<IonIcon slot='start' icon={home}></IonIcon>
						</IonItem>
					</IonMenuToggle>
					<IonMenuToggle>
						<IonItem
							routerLink='/customer-transaction'
							routerDirection='forward'
						>
							<IonLabel>Le mie transazioni</IonLabel>
							<IonIcon slot='start' icon={listCircle}></IonIcon>
						</IonItem>
					</IonMenuToggle>
					<IonMenuToggle>
						<IonItem>
							<IonLabel>Supporto</IonLabel>
							<IonIcon slot='start' icon={informationCircle}></IonIcon>
						</IonItem>
					</IonMenuToggle>
					<IonMenuToggle>
						<IonItem
							routerLink='/registered-businesses'
							routerDirection='forward'
						>
							<IonLabel>Attività convenzionate</IonLabel>
							<IonIcon slot='start' icon={storefrontOutline}></IonIcon>
						</IonItem>
					</IonMenuToggle>
					{/* <IonMenuToggle>
						<IonItem
							routerLink='/registered-businesses-online'
							routerDirection='forward'
						>
							<IonLabel>Attività convenzionate online</IonLabel>
							<IonIcon slot='start' icon={desktopOutline}></IonIcon>
						</IonItem>
					</IonMenuToggle> */}
					<IonMenuToggle>
						<IonItem routerLink='/customer-win-list' routerDirection='forward'>
							<IonLabel>Le mie vincite</IonLabel>
							<IonIcon slot='start' icon={trophy}></IonIcon>
						</IonItem>
					</IonMenuToggle>
					<IonMenuToggle>
						<IonItem
							onClick={async () => {
								await logout();
								dispatch(
									authStateChanged({
										userType: undefined,
										userUID: undefined,
										name: undefined,
									})
								);
							}}
						>
							<IonLabel>Logout</IonLabel>
							<IonIcon slot='start' icon={logOut}></IonIcon>
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default CustomerMenu;
