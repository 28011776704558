import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
	getFirestore,
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
	orderBy,
	limit,
} from 'firebase/firestore';
import {
	getAuth,
	signInWithEmailAndPassword,
	confirmPasswordReset,
	User,
} from 'firebase/auth';
import {
	HttpsCallableResult,
	getFunctions,
	httpsCallable,
} from 'firebase/functions';

import { clearUserData, getUserData } from './storage';
import { onSnapshot } from 'firebase/firestore';
import { getISOWeek } from 'date-fns';
import { BusinessEvent } from '../pages/Events/types';

const moment = require('moment');
const PAGE_SIZE = 10;

// important: replace firebase config with the relative account configuration
const firebaseConfig = {
	apiKey: 'AIzaSyAuBsIG4Y-WZexRhTTD0IgdqHQinX4vfaA',
	authDomain: 'jackshop-3dce5.firebaseapp.com',
	projectId: 'jackshop-3dce5',
	storageBucket: 'jackshop-3dce5.appspot.com',
	messagingSenderId: '987574864712',
	appId: '1:987574864712:web:4ebf1b11bcb8f33eb2017d',
	measurementId: 'G-GR44QFQBDE',
};

const app = firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');
const auth = getAuth();

export const loginWithEmail = async (email: string, password: string) => {
	return signInWithEmailAndPassword(auth, email, password);
};

export const confirmRegistration = async (
	c: string,
	at: string,
	password: string,
	email: string
) => {
	const confirmAccountRegistration = httpsCallable(
		functions,
		'confirmAccountRegistration'
	);

	return confirmAccountRegistration({ c, at, password, email });
};

export const verifyAuthState = (
	authStateCallback: (arg0: User | null) => void
) => {
	auth.onAuthStateChanged((user) => {
		authStateCallback(user);
	});
};

export const getJackpotRealtime = (callback: (doc: any) => void) => {
	const currentWeek = getISOWeek(new Date());
	const currentYear = moment().year();

	return onSnapshot(
		doc(db, 'jackpot', `${currentYear}_${currentWeek}`),
		callback
	);
};

export const getCustomerCreditRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	console.log('get business ' + userData.userUID);

	const q = query(
		collection(db, 'customer-accounts'),
		where('accountUID', '==', userData.userUID)
	);

	return onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			console.log(
				'🚀 ~ file: firestore.ts ~ line 96 ~ snapshot.docChanges ~ change',
				change
			);
			callback(change);
		});
	});
};

export const getBusinessAccountInfoRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	console.log('get business ' + userData.userUID);
	const q = query(
		collection(db, 'business-accounts'),
		where('accountUID', '==', userData.userUID)
	);

	onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			callback(change);
		});
	});
};

/**
 * Get customer transactions
 * @returns
 */
export const getCustomerTransactions = async () => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'transactions'),
		where('customerUID', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	const querySnapshot = await getDocs(q);
	const results: any[] = [];
	querySnapshot.forEach((doc) => {
		results.push(doc.data());
	});

	return results;
};

/**
 * Get customer transactions
 * @returns
 */
export const getCustomerTransactionsRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'transactions'),
		where('customerUID', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

// export const getInfluencerTransactionsRealtime = async (
// 	callback: (doc: any) => void
// ) => {
// 	const userData = await getUserData();
// 	const userUID = userData.userUID;

// 	const q = query(
// 		collection(db, 'transactions'),
// 		where('influencerUID', '==', userUID),
// 		orderBy('creationDate', 'desc')
// 	);

// 	onSnapshot(q, callback);
// };

// export const getInfluencerInvitedPeoplesRealtime = async (
// 	callback: (doc: any) => void
// ) => {
// 	const userData = await getUserData();
// 	const userUID = userData.userUID;

// 	// const q = query(
// 	// 	collection(db, 'transactions'),
// 	// 	where('influencerUID', '==', userUID),
// 	// 	orderBy('creationDate', 'desc')
// 	// );
// 	const q = query(
// 		collection(db, 'customer-accounts'),
// 		where('invitedBy', '==', userUID),
// 		orderBy('creationDate', 'desc')
// 	);

// 	onSnapshot(q, callback);
// };

// export const getInfluencerCreditRealtime = async (
// 	callback: (doc: any) => void
// ) => {
// 	const userData = await getUserData();
// 	const userUID = userData.userUID;

// 	const q = query(
// 		collection(db, 'influencer-transactions'),
// 		where('influencerUID', '==', userUID),
// 		orderBy('creationDate', 'desc')
// 	);

// 	onSnapshot(q, (snapshot) => {
// 		let totalAmount = 0;
// 		snapshot.forEach((doc) => {
// 			const data = doc.data();

// 			if (data.amount) {
// 				totalAmount += data.amount;
// 			}
// 		});
// 		callback(totalAmount);
// 	});
// };

// export const getInfluencerCustomersCreditRealtime = async (
// 	callback: (doc: any) => void
// ) => {
// 	const userData = await getUserData();
// 	const userUID = userData.userUID;

// 	const q = query(
// 		collection(db, 'influencer-transactions'),
// 		where('influencerUID', '==', userUID),
// 		orderBy('creationDate', 'desc')
// 	);

// 	onSnapshot(q, (snapshot) => {
// 		const customerTotals: { [customerUID: string]: number } = {};

// 		snapshot.forEach((doc) => {
// 			const data = doc.data();
// 			const customerUID = data.customerReference?.customerUID;

// 			if (data.amount && customerUID) {
// 				if (!customerTotals[customerUID]) {
// 					customerTotals[customerUID] = 0;
// 				}
// 				customerTotals[customerUID] += data.amount;
// 			}
// 		});

// 		callback(customerTotals);
// 	});
// };

export const getRegisteredBusinessesRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'business-accounts'),
		where('addedBy', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getAllRegisteredBusinessesRealtime = async (
	callback: (doc: any) => void
) => {
	const q = query(
		collection(db, 'business-accounts'),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getRegisteredInfluencersRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'influencer-accounts'),
		where('addedBy', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getAllRegisteredInfluencersRealtime = async (
	callback: (doc: any) => void
) => {
	const q = query(
		collection(db, 'influencer-accounts'),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getAllRegisteredAgentsRealtime = async (
	callback: (doc: any) => void
) => {
	const q = query(
		collection(db, 'agent-accounts')
		// orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

/**
 * Get transactions for a specific business
 * @returns
 */
export const getBusinessTransactionsRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	const businessUID = userData.userUID;

	console.log('b uid: ' + businessUID);
	const q = query(
		collection(db, 'transactions'),
		where('businessUID', '==', businessUID),
		orderBy('creationDate', 'desc'),
		limit(PAGE_SIZE)
	);

	onSnapshot(q, callback);
};

export const getBusinessCreditRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	const businessUID = userData.userUID;
	const q = query(
		collection(db, 'transactions'),
		where('accountUID', '==', businessUID),
		orderBy('creationDate', 'desc'),
		limit(PAGE_SIZE)
	);

	onSnapshot(q, callback);
};

/**
 * Logout from firebase
 */
export const logout = async () => {
	await auth.signOut();
	await clearUserData();
};

/**
 * Get current logged user
 * @returns
 */
export const isUserLogged = () => {
	return auth.currentUser;
};

/**
 * Create a new transaction
 * @param amountSpent
 * @param customerUID
 * @returns
 */
export const createTransaction = (amountSpent: number, customerUID: string) => {
	const createTransaction = httpsCallable(functions, 'createTransaction');
	return createTransaction({
		amountSpent: amountSpent,
		customerUID: customerUID,
	});
};

export const consumeCustomerCredit = (amount: number, customerUID: string) => {
	const consumeCustomerCredit = httpsCallable(
		functions,
		'consumeCustomerCredit'
	);
	return consumeCustomerCredit({
		amount,
		customerUID,
	});
};

export const consumeBusinessCredit = async (amount: number) => {
	const consumeBusinessCredit = httpsCallable(
		functions,
		'consumeBusinessCredit'
	);
	const userData = await getUserData();

	const businessUID = userData.userUID;
	return consumeBusinessCredit({
		amount,
		businessUID,
	});
};

/**
 * Create new customer account
 * @param user
 * @returns
 */
// export const createUserWithEmail = (user: any) => {
// 	const createUserWithEmail = httpsCallable(functions, 'createUserWithEmail');
// 	return createUserWithEmail(user);
// };

/**
 * Create new customer account
 * @param user
 * @returns
 */
export const registerCustomer = (user: any) => {
	const registerCustomer = httpsCallable(functions, 'registerCustomer');
	return registerCustomer(user);
};

export const registerAgentAccount = (agent: any) => {
	const registerAgentAccount = httpsCallable(functions, 'registerAgent');
	return registerAgentAccount(agent);
};

export const registerInfluencerAccount = (agent: any) => {
	const registerInfluencerAccount = httpsCallable(
		functions,
		'registerInfluencer'
	);
	return registerInfluencerAccount(agent);
};

export const registerBusinessAccount = (
	businessData: any,
	isOnline: boolean
) => {
	const registerBusinessAccount = httpsCallable(functions, 'registerBusiness');

	return registerBusinessAccount({ ...businessData, isOnline });
};

export const sendConfirmationEmail = (email: string, at: string) => {
	const sendConfirmationEmail = httpsCallable(
		functions,
		'sendConfirmationEmail'
	);

	return sendConfirmationEmail({ email, at });
};

export const updateBusinessCredit = (
	businessUid: string,
	businessCredit: number
) => {
	const updateBusinessCredit = httpsCallable(functions, 'updateBusinessCredit');
	return updateBusinessCredit({ businessUid, businessCredit });
};

export const getRegisteredBusinesses = (
	searchCity: string,
	onlineBusinesses?: boolean
) => {
	const getRegisteredBusinessesFunc = httpsCallable(
		functions,
		'getRegisteredBusinesses'
	);
	return getRegisteredBusinessesFunc({
		searchCity,
		onlineBusinesses,
	});
};

export const requestResetPassword = async (email: string) => {
	const requestResetPasswordFn = httpsCallable(
		functions,
		'sendPasswordResetEmail'
	);
	return await requestResetPasswordFn({ email });
};

export const confirmResetPassword = async (
	token: string,
	newPassword: string,
	email: string,
	at: string
) => {
	try {
		const confirmResetPasswordCallable = httpsCallable(
			functions,
			'confirmResetPassword'
		);

		const result = await confirmResetPasswordCallable({
			token,
			newPassword,
			email,
			at,
		});

		return result.data;
	} catch (error) {
		console.error('Error confirming password reset:', error);
		throw error; // Gestione degli errori nel chiamante della funzione
	}
};

export const getEvents = (
	//latitude: number | null,
	// longitude: number | null,
	searchCity?: string,
	searchTitle?: string
): Promise<HttpsCallableResult<{ events: BusinessEvent[] }>> => {
	const getAllEvents = httpsCallable(functions, 'getEvents');
	return getAllEvents({
		//latitude,
		// longitude,
		searchCity,
		searchTitle,
	}) as Promise<HttpsCallableResult<{ events: BusinessEvent[] }>>;
};

export const getCountCustomers = (
	latitude: number | null,
	longitude: number | null,
	searchCity?: string
): Promise<
	HttpsCallableResult<{
		success: boolean;
		count?: number;
		error?: boolean;
		message?: string;
	}>
> => {
	const countCustomersCallable = httpsCallable(functions, 'countCustomers');
	return countCustomersCallable({
		latitude,
		longitude,
		city: searchCity,
	}) as Promise<
		HttpsCallableResult<{
			success: boolean;
			count?: number;
			error?: boolean;
			message?: string;
		}>
	>;
};

export const getEventById = (
	eventId: string
): Promise<HttpsCallableResult<BusinessEvent>> => {
	const getSingleEvent = httpsCallable(functions, 'getEventById');
	return getSingleEvent({ eventId }) as Promise<
		HttpsCallableResult<BusinessEvent>
	>;
};

export const saveEvent = async (eventData: {
	title: string;
	description: string;
	image: string;
	category: string;
	city: string;
	startDate: Date | string;
	endDate: Date | string;
	latitude: number;
	longitude: number;
}) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const saveNewEvent = httpsCallable(functions, 'createNewEvent');
	return saveNewEvent({
		eventData: {
			...eventData,
			userUID,
		},
	});
};

export const getBusinessEvents = async () => {
	const userData = await getUserData();
	const businessUID = userData.userUID;
	const getBusinessEventsFunc = httpsCallable(functions, 'getBusinessEvents');
	const result: any = await getBusinessEventsFunc();
	return result.data.events;
};

export const getEventsCount = async () => {
	const userData = await getUserData();
	const businessUID = userData.userUID;
	const getEventsCountFunc = httpsCallable(functions, 'getEventsCount');
	const result: any = await getEventsCountFunc();
	return result.data.eventsCount;
};

export const deleteBusinessEvent = async (eventId: string) => {
	const deleteEventFunc = httpsCallable(functions, 'deleteEvent');
	const result: any = await deleteEventFunc({ eventId });
	return result.data;
};

export const createPaymentIntent = async (eventData: any) => {
	const createPaymentIntentFunction = httpsCallable(
		functions,
		'createPaymentIntent'
	);
	const result: any = await createPaymentIntentFunction({ eventData });
	if (result.data.error) {
		throw new Error(result.data.message);
	}
	return result.data.url;
};

export const getTotalCustomersRealtime = (
	callback: (snapshot: any) => void
) => {
	const customersCollection = collection(db, 'customer-accounts');

	const q = query(customersCollection, where('state', '==', 'ENABLED'));

	onSnapshot(q, callback);
};
