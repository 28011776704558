// import React, { useState, useEffect } from 'react';
// import {
// 	IonPage,
// 	IonHeader,
// 	IonToolbar,
// 	IonTitle,
// 	IonContent,
// 	IonButton,
// 	IonList,
// 	IonItem,
// 	IonLabel,
// 	IonButtons,
// 	IonBackButton,
// 	useIonRouter,
// 	useIonLoading,
// 	useIonToast,
// 	IonIcon,
// } from '@ionic/react';
// import {
// 	deleteBusinessEvent,
// 	getBusinessEvents,
// } from '../../../services/firestore';
// import { useHistory } from 'react-router';
// import { trash } from 'ionicons/icons';

// const BusinessEvents: React.FC = () => {
// 	const history = useHistory();
// 	const [events, setEvents] = useState<any[]>([]);
// 	const [presentLoader, dismissLoader] = useIonLoading();
// 	const router = useIonRouter();
// 	const [presentToast] = useIonToast();
// 	useEffect(() => {
// 		const fetchEvents = async () => {
// 			try {
// 				presentLoader();
// 				const result = await getBusinessEvents();
// 				console.log(result);

// 				setEvents(result);
// 			} catch (error) {
// 				console.error('Error fetching business events:', error);
// 			} finally {
// 				dismissLoader();
// 			}
// 		};

// 		fetchEvents();
// 	}, []);

// 	console.log(events);

// 	const handleEventClick = (event: any) => {
// 		history.push(`/business-events/${event.id}`, { event, isMyEvent: true });
// 	};

// 	const handleDeleteEvent = async (eventId: string) => {
// 		try {
// 			presentLoader('Cancellazione in corso..');
// 			await deleteBusinessEvent(eventId);
// 			setEvents((prevEvents) =>
// 				prevEvents.filter((event) => event.id !== eventId)
// 			);
// 			presentToast({
// 				message: 'Event deleted successfully',
// 				duration: 2000,
// 				position: 'top',
// 			});
// 			dismissLoader();
// 		} catch (error) {
// 			console.error('Error deleting event:', error);
// 			presentToast({
// 				message: 'Error deleting event',
// 				duration: 2000,
// 				position: 'top',
// 			});
// 		}
// 	};

// 	const [oldEvents, setOldEvents] = useState(false);
// 	const today = new Date();

// 	// Ordina gli eventi per data di fine (dalla più recente alla più lontana)
// 	const sortedEvents = [...events].sort(
// 		(a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
// 	);

// 	const currentEvents = sortedEvents.filter(
// 		(event) => new Date(event.endDate) >= today
// 	);
// 	const expiredEvents = sortedEvents.filter(
// 		(event) => new Date(event.endDate) < today
// 	);

// 	return (
// 		<IonPage>
// 			<IonHeader>
// 				<IonToolbar color='tertiary'>
// 					<IonTitle>I Miei Eventi</IonTitle>
// 					<IonButtons slot='start'>
// 						<IonBackButton></IonBackButton>
// 					</IonButtons>
// 				</IonToolbar>
// 			</IonHeader>
// 			<IonContent className='ion-padding'>
// 				<IonButton expand='full' onClick={() => router.push(`/new-event`)}>
// 					Crea Nuovo Evento
// 				</IonButton>
// 				<IonButton expand='full' onClick={() => setOldEvents(true)}>
// 					Eventi Scaduti
// 				</IonButton>
// 				{oldEvents && (
// 					<>
// 						<IonTitle color='white'>
// 							Lista eventi scaduti, saranno cancellati dopo 30 giorni
// 						</IonTitle>

// 						<IonButton expand='full' onClick={() => setOldEvents(false)}>
// 							Torna agli Eventi
// 						</IonButton>
// 					</>
// 				)}
// 				<IonList>
// 					{(oldEvents ? expiredEvents : currentEvents).length > 0 ? (
// 						(oldEvents ? expiredEvents : currentEvents).map((event) => (
// 							<IonItem key={event.id} onClick={() => handleEventClick(event)}>
// 								<IonLabel>
// 									{oldEvents && <h1>SCADUTO</h1>}
// 									<h2>{event.title}</h2>
// 									<p>{event.description}</p>
// 									<p>
// 										<strong>Categoria:</strong> {event.category}
// 									</p>
// 									<p>
// 										<strong>Città:</strong> {event.city}
// 									</p>
// 									<p>
// 										<strong>Data di Inizio:</strong>{' '}
// 										{new Date(event.startDate).toLocaleString()}
// 									</p>
// 									<p>
// 										<strong>Data di Fine:</strong>{' '}
// 										{new Date(event.endDate).toLocaleString()}
// 									</p>
// 									<p>
// 										<strong>Immagine:</strong>{' '}
// 										<img
// 											src={event.image}
// 											alt={event.title}
// 											style={{ width: '100px' }}
// 										/>
// 									</p>
// 									<p>
// 										<strong>Latitudine:</strong> {event.latitude}
// 									</p>
// 									<p>
// 										<strong>Longitudine:</strong> {event.longitude}
// 									</p>
// 								</IonLabel>
// 								<IonButton
// 									fill='clear'
// 									color='danger'
// 									onClick={(e) => {
// 										e.stopPropagation();
// 										handleDeleteEvent(event.id);
// 									}}
// 								>
// 									<IonIcon slot='icon-only' icon={trash}></IonIcon>
// 								</IonButton>
// 							</IonItem>
// 						))
// 					) : (
// 						<p>
// 							{oldEvents
// 								? 'Nessun evento scaduto'
// 								: 'Nessun evento corrente, creane uno'}
// 						</p>
// 					)}
// 				</IonList>
// 			</IonContent>
// 		</IonPage>
// 	);
// };

// export default BusinessEvents;

import React, { useState, useEffect } from 'react';
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	IonButtons,
	IonBackButton,
	useIonRouter,
	useIonLoading,
	useIonToast,
	IonIcon,
} from '@ionic/react';
import {
	deleteBusinessEvent,
	getBusinessEvents,
} from '../../../services/firestore';
import { useHistory } from 'react-router';
import { trash } from 'ionicons/icons';

const BusinessEvents: React.FC = () => {
	const history = useHistory();
	const [events, setEvents] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(true); // Stato per il caricamento
	const [presentLoader, dismissLoader] = useIonLoading();
	const router = useIonRouter();
	const [presentToast] = useIonToast();

	useEffect(() => {
		let isMounted = true; // Flag per verificare se il componente è montato

		const fetchEvents = async () => {
			try {
				presentLoader('Caricamento in corso...');
				const result = await getBusinessEvents();
				if (isMounted) {
					setEvents(result); // Aggiorna lo stato solo se il componente è montato
				}
			} catch (error) {
				console.error('Error fetching business events:', error);
			} finally {
				if (isMounted) {
					dismissLoader();
					setIsLoading(false); // Fine caricamento
				}
			}
		};

		fetchEvents();

		return () => {
			isMounted = false; // Aggiorna il flag quando il componente viene smontato
			dismissLoader(); // Assicurati che il loader venga nascosto
		};
	}, []);

	const handleEventClick = (event: any) => {
		history.push(`/business-events/${event.id}`, { event, isMyEvent: true });
	};

	const handleDeleteEvent = async (eventId: string) => {
		try {
			presentLoader('Cancellazione in corso...');
			await deleteBusinessEvent(eventId);
			setEvents((prevEvents) =>
				prevEvents.filter((event) => event.id !== eventId)
			);
			presentToast({
				message: 'Evento cancellato con successo',
				duration: 2000,
				position: 'top',
			});
		} catch (error) {
			console.error('Errore durante la cancellazione:', error);
			presentToast({
				message: 'Errore durante la cancellazione',
				duration: 2000,
				position: 'top',
			});
		} finally {
			dismissLoader();
		}
	};

	const [oldEvents, setOldEvents] = useState(false);
	const today = new Date();

	const sortedEvents = [...events].sort(
		(a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
	);

	const currentEvents = sortedEvents.filter(
		(event) => new Date(event.endDate) >= today
	);
	const expiredEvents = sortedEvents.filter(
		(event) => new Date(event.endDate) < today
	);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>I Miei Eventi {oldEvents && 'Passati'}</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				{isLoading ? (
					<p>Caricamento in corso...</p>
				) : (
					<>
						<IonButton expand='full' onClick={() => router.push(`/new-event`)}>
							Crea Nuovo Evento
						</IonButton>
						<IonButton expand='full' onClick={() => setOldEvents(true)}>
							Eventi Scaduti
						</IonButton>
						{oldEvents && (
							<>
								<IonTitle style={{ color: 'white' }}>
									Lista eventi scaduti
									{/* , saranno cancellati dopo 30 giorni */}
								</IonTitle>
								<IonButton expand='full' onClick={() => setOldEvents(false)}>
									Torna agli Eventi
								</IonButton>
							</>
						)}
						<IonList>
							{(oldEvents ? expiredEvents : currentEvents).length > 0 ? (
								(oldEvents ? expiredEvents : currentEvents).map((event) => (
									<IonItem
										key={event.id}
										onClick={() => handleEventClick(event)}
									>
										<IonLabel>
											{oldEvents && <h1>SCADUTO</h1>}
											<h2>{event.title}</h2>
											<p>{event.description}</p>
											<p>
												<strong>Categoria:</strong> {event.category}
											</p>
											<p>
												<strong>Città:</strong> {event.city}
											</p>
											<p>
												<strong>Data di Inizio:</strong>{' '}
												{new Date(event.startDate).toLocaleString()}
											</p>
											<p>
												<strong>Data di Fine:</strong>{' '}
												{new Date(event.endDate).toLocaleString()}
											</p>
											<p>
												<strong>Immagine:</strong>{' '}
												<img
													src={event.image}
													alt={event.title}
													style={{ width: '100px' }}
												/>
											</p>
											<p>
												<strong>Latitudine:</strong> {event.latitude}
											</p>
											<p>
												<strong>Longitudine:</strong> {event.longitude}
											</p>
										</IonLabel>
										<IonButton
											fill='clear'
											color='danger'
											onClick={(e) => {
												e.stopPropagation();
												handleDeleteEvent(event.id);
											}}
										>
											<IonIcon slot='icon-only' icon={trash}></IonIcon>
										</IonButton>
									</IonItem>
								))
							) : (
								<p>
									{oldEvents
										? 'Nessun evento scaduto'
										: 'Nessun evento corrente, creane uno'}
								</p>
							)}
						</IonList>
					</>
				)}
			</IonContent>
		</IonPage>
	);
};

export default BusinessEvents;
