import React, { useState, useEffect } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonImg,
	IonText,
	useIonRouter,
	IonButtons,
	IonBackButton,
	IonIcon,
	IonSelect,
	IonSelectOption,
	IonSearchbar,
	IonGrid,
	IonCol,
	IonRow,
	useIonToast,
	useIonLoading,
	IonButton,
} from '@ionic/react';
import './EventsList.scss';
import { arrowBack } from 'ionicons/icons';
import { getEvents } from '../../services/firestore';
import { BusinessEvent } from './types';
import { useHistory } from 'react-router';

const EventsList: React.FC = () => {
	const router = useIonRouter();
	const history = useHistory();
	const [events, setEvents] = useState<BusinessEvent[]>([]);
	const [currentCity, setCurrentCity] = useState('');
	const [selectedCategory, setSelectedCategory] =
		useState<string>('Tutte le categorie');
	const [searchTitle, setSearchTitle] = useState<string>('');
	const [latitude, setLatitude] = useState<number | null>(null);
	const [longitude, setLongitude] = useState<number | null>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [limit] = useState<number>(10); // Numero di eventi per pagina
	const [presentToast] = useIonToast();
	const [presentLoader, dismissLoader] = useIonLoading();

	// useEffect(() => {
	// 	if (navigator.geolocation) {
	// 		navigator.geolocation.getCurrentPosition(
	// 			(position) => {
	// 				setLatitude(position.coords.latitude);
	// 				setLongitude(position.coords.longitude);
	// 			},
	// 			(error) => {
	// 				console.error('Errore nella geolocalizzazione: ', error);
	// 				presentToast({
	// 					message: 'Errore nella geolocalizzazione',
	// 					duration: 2000,
	// 					position: 'top',
	// 				});
	// 			}
	// 		);
	// 	}
	// }, [presentToast]);

	const fetchEvents = async () => {
		presentLoader();
		try {
			const response: any = await getEvents(
				currentCity,
				searchTitle
				// currentPage,
				// limit
			);

			// setEvents(response.data.events);
			setEvents(response.data);

			// setTotalPages(response.data.totalPages);
		} catch (error) {
			console.error('Errore nel recupero degli eventi: ', error);
			presentToast({
				message: 'Errore nel recupero degli eventi',
				duration: 2000,
				position: 'top',
			});
		} finally {
			dismissLoader();
		}
	};

	useEffect(
		() => {
			fetchEvents();
		},
		[
			//currentCity, searchTitle, currentPage
		]
	);

	// const filterEvents = (event: BusinessEvent) => {
	// 	const matchesCategory =
	// 		selectedCategory === 'Tutte le categorie' ||
	// 		event.category === selectedCategory;

	// 	const matchesTitle = event.title
	// 		.toLowerCase()
	// 		.includes(searchTitle.toLowerCase());

	// 	const matchesCity = event.city
	// 		.toLowerCase()
	// 		.includes(currentCity.toLowerCase());

	// 	return matchesCategory && matchesTitle && matchesCity;
	// };

	const filterEvents = (event: BusinessEvent) => {
		const eventStartDate = new Date(event.startDate).getTime();
		const eventEndDate = new Date(event.endDate).getTime();
		const currentDate = Date.now();
		const threeDaysFromNow = currentDate + 3 * 24 * 60 * 60 * 1000; // 3 giorni in millisecondi

		if (eventStartDate <= threeDaysFromNow && eventEndDate >= currentDate) {
			const matchesCategory =
				selectedCategory === 'Tutte le categorie' ||
				event.category === selectedCategory;

			const matchesTitle = event.title
				.toLowerCase()
				.includes(searchTitle.toLowerCase());

			const cityParts = event.city
				.toLowerCase()
				.split(',')
				.map((part) => part.trim());

			const matchesCity = cityParts.some((part) =>
				part.includes(currentCity.trim().toLowerCase())
			);

			console.log('event.city:', event.city.toLowerCase());
			console.log('currentCity:', currentCity.trim().toLowerCase());

			return matchesCategory && matchesTitle && matchesCity;
		}

		return false;
	};

	const handleEventClick = (event: BusinessEvent) => {
		history.push(`/events/${event.id}`, { event });
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Eventi</IonTitle>
					<IonItem routerLink='/customer-home' routerDirection='back'>
						<IonLabel>Torna alla Home</IonLabel>
						<IonIcon slot='start' icon={arrowBack}></IonIcon>{' '}
					</IonItem>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<IonToolbar>
					<IonSelect
						value={selectedCategory}
						placeholder='Seleziona categoria'
						onIonChange={(e) => setSelectedCategory(e.detail.value)}
					>
						<IonSelectOption value='Tutte le categorie'>
							Tutte le categorie
						</IonSelectOption>
						{Array.from(new Set(events.map((event) => event.category))).map(
							(category) => (
								<IonSelectOption key={category} value={category}>
									{category}
								</IonSelectOption>
							)
						)}
					</IonSelect>
					<IonSearchbar
						value={searchTitle}
						placeholder='Cerca per titolo'
						onIonChange={(e) => setSearchTitle(e.detail.value!)}
					/>
					<IonSearchbar
						value={currentCity}
						placeholder='Cerca per città'
						onIonChange={(e) => setCurrentCity(e.detail.value!)}
					/>
				</IonToolbar>
				<IonList>
					{events.filter(filterEvents).map((event) => (
						<IonItem
							className='event-item'
							onClick={() => handleEventClick(event)}
							key={event.id}
							button
						>
							<IonImg
								src={event.image}
								alt={event.title}
								className='event-image'
							/>
							<IonLabel className='event-details'>
								<h2>
									<strong>{event.businessName}</strong>
								</h2>
								<h2>{event.title}</h2>
								<p>{event.description}</p>
								<p>{event.city}</p>
								<IonText color='medium'>
									<small className='event-category'>{event.category}</small>
								</IonText>
							</IonLabel>
							{/* <IonLabel>
								<h2>{event.title}</h2>
								<p>{event.city}</p>
							</IonLabel> */}
						</IonItem>
					))}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default EventsList;
