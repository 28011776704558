import React, { useState, useEffect } from 'react';
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonItem,
	IonLabel,
	IonCheckbox,
	IonButtons,
	IonBackButton,
	useIonAlert,
	useIonLoading,
	useIonRouter,
	IonButton,
	IonList,
	useIonToast,
} from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CustomForm from '../../../components/CustomForm/CustomForm';
import { registerBusinessAccount } from '../../../services/firestore';
import * as Yup from 'yup';
import { REGEX, VALIDATION_MESSAGE } from '../../../app/utils/constants';

const AgentRegisterNewBusiness: React.FC = () => {
	const dispatch = useAppDispatch();
	const router = useIonRouter();
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [presentToast, dismissToast] = useIonToast();
	const [clearForm, setClearForm] = useState(false);
	//const [onlineBusiness, setOnlineBusiness] = useState(false);
	const userType = useAppSelector((state) => state.authSlice.userType);

	const [address, setAddress] = useState('');
	const [latitude, setLatitude] = useState<number | null>(null);
	const [longitude, setLongitude] = useState<number | null>(null);

	useEffect(() => {
		const fetchCoordinates = async (address: string) => {
			const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
				address
			)}`;

			try {
				const response = await fetch(url);
				const data = await response.json();

				if (data.length > 0) {
					const { lat, lon } = data[0];
					setLatitude(parseFloat(lat));
					setLongitude(parseFloat(lon));
				} else {
					setLatitude(null);
					setLongitude(null);
				}
			} catch (error) {
				console.error('Error fetching coordinates:', error);
			}
		};

		if (address) {
			fetchCoordinates(address);
		}
	}, [address]);

	const fields = [
		{
			label: 'Nome attività',
			props: {
				name: 'businessName',
				type: 'text' as const,
				placeholder: 'Nome attività',
			},
		},
		{
			label: 'Nome titolare',
			props: {
				name: 'businessOwner',
				type: 'text' as const,
				placeholder: 'Titolare attività',
			},
		},
		{
			label: 'Indirizzo',
			props: {
				name: 'address',
				type: 'text' as const,
				placeholder: 'Indirizzo',
				onIonChange: (e: any) => setAddress(e.detail.value),
			},
		},
		{
			label: 'Città',
			props: {
				name: 'city',
				type: 'text' as const,
				placeholder: 'Città',
			},
		},
		{
			label: 'Partita IVA',
			props: {
				name: 'vatNumber',
				type: 'text' as const,
				placeholder: 'Partita IVA',
			},
		},
		{
			label: 'Numero di telefono',
			props: {
				name: 'telephone',
				type: 'tel' as const,
				placeholder: 'Numero di telefono',
			},
		},
		{
			label: 'Email',
			props: {
				name: 'email',
				type: 'email' as const,
				placeholder: 'Email',
			},
		},
	];

	// const onlineFields = [
	// 	{
	// 		label: 'Nome attività',
	// 		props: {
	// 			name: 'businessName',
	// 			type: 'text' as const,
	// 			placeholder: 'Nome attività',
	// 		},
	// 	},
	// 	{
	// 		label: 'URL sito web',
	// 		props: {
	// 			name: 'url',
	// 			type: 'url' as const,
	// 			placeholder: 'URL sito web',
	// 		},
	// 	},
	// ];

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.matches(REGEX.EMAIL, VALIDATION_MESSAGE.EMAIL_NOT_VALID)
			.required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		businessName: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		businessOwner: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		address: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		city: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		vatNumber: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		telephone: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	// const onlineBusinessValidationSchema = Yup.object().shape({
	// 	businessName: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	// 	url: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	// });

	const onSubmit = async (data: any) => {
		console.log(
			'🚀 ~ file: AgentRegisterNewBusiness.tsx ~ line 146 ~ onSubmit ~ data',
			data
		);
		presentLoader('Registrazione in corso...');
		setClearForm(false);
		try {
			const result: any = await registerBusinessAccount(
				{
					...data,
					latitude,
					longitude,
					eventsCount: 0,
				},
				false // onlineBusiness
			);

			if (result.data?.error) {
				if (
					result.data.error === 'auth/email-already-exists' ||
					result.data.code === 'BUSINESS_ACCOUNT_ALREADY_EXISTS'
				) {
					presentAlert('Email già registrata su JackShop');
				} else {
					presentAlert("Errore imprevisto durante la creazione dell'account");
				}
			} else {
				presentAlert('Registrazione avvenuta correttamente', [
					{
						text: 'OK',
						handler: () => {
							setClearForm(true);
							router.push('/agent-home');
						},
					},
				]);
			}
		} catch (e) {
			console.error(e);
			presentAlert("Errore imprevisto durante la creazione dell'account");
		} finally {
			dismissLoader();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Registra attività</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='ion-padding'>
					{/* <IonItem className='is-online-wrapper'>
						<IonLabel>Attività Online</IonLabel>
						<IonCheckbox
							checked={onlineBusiness}
							onIonChange={(e) => setOnlineBusiness(e.detail.checked)}
						/>
					</IonItem>
					{onlineBusiness && (
						<CustomForm
							fields={onlineFields}
							validationSchema={onlineBusinessValidationSchema}
							submitLabel={'Registra attività'}
							onSubmit={onSubmit}
							clearForm={clearForm}
						></CustomForm>
					)} */}
					{/* {!onlineBusiness && ( */}
					<CustomForm
						fields={fields}
						validationSchema={validationSchema}
						submitLabel={'Registra attività'}
						onSubmit={onSubmit}
						clearForm={clearForm}
					></CustomForm>
					{/* )} */}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AgentRegisterNewBusiness;
